import React, { useState } from "react";
import styled from "styled-components";
import PageLayout from "../../components/PageLayout";
import { Button, Container, Form, Row } from "react-bootstrap";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Trans } from "react-i18next";
import { graphql } from "gatsby";
import { Crisp } from "crisp-sdk-web";

import * as Styles from "../css/concern.module.scss";
import { api } from "../../utilities/api";
import { goTo } from "../../utilities/utils";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "elevio-element": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export default function support() {
  const [phoneOrCustomerId, updatePhoneOrCustomerId] = useState<string>("-");
  const [contactThrough, updateContactThrough] = useState<string>("-");
  const [isMissingSelect, updateIsMissingSelect] = useState<boolean[]>(
    Array(5).fill(true)
  );

  const updateMissingSelect = (index: number) => {
    updateIsMissingSelect((s) => {
      const newArray = [...s];
      newArray[index] = false;
      return newArray;
    });
  };

  return (
    <PageLayout>
      <title>
        <Trans>Report a Concern | PhoneBox</Trans>
      </title>
      <Container className={Styles.reportAConcernContainer}>
        <h2 style={{ marginBottom: "20px" }}>
          <Trans>Report a Concern</Trans>
        </h2>
        <Row
          style={{
            marginBottom: "40px",
          }}
        >
          <h6>
            <Trans>Chat with Us</Trans>
          </h6>
          <p>
            <Trans>
              Connect with one of our customer support representatives for
              step-by-step assistance. We're here to help resolve your concerns
              efficiently.
            </Trans>
          </p>
        </Row>
        <Row
          style={{
            marginBottom: "40px",
          }}
        >
          <Button
            type="button"
            onClick={() => {
              Crisp.chat.open();
            }}
          >
            <Trans>Chat Now</Trans>
          </Button>
          <p>
            <Trans>Chat Hours: Monday to Sunday, 9:30 AM to 6:30 PM PT</Trans>
          </p>
        </Row>
        <Row
          style={{
            marginBottom: "40px",
          }}
        >
          <h6>
            <Trans>Give Us a Call</Trans>
          </h6>
          <p>
            <Trans>
              Speak with one of our customer support representatives to address
              your concerns. Call us directly at:
            </Trans>
          </p>
          <p>
            <Trans>
              Phone Support Hours: Monday to Sunday, 9:30 AM to 6:30 PM PT
            </Trans>
          </p>
          <p>
            <Trans>Toll-Free: +1 855 886 0505</Trans>
          </p>
        </Row>
        <Row
          style={{
            marginBottom: "40px",
          }}
        >
          <h6>
            <Trans>Email or Write to Us</Trans>
          </h6>
          <p>
            <Trans>Send us an email at</Trans>{" "}
            <a href="mailto:services@gophonebox.com">
              <Trans>services@gophonebox.com</Trans>
            </a>
            <Trans>to share your concern</Trans>.
          </p>
          <p>
            <Trans>
              If you prefer, you can also send your concern by mail to:
            </Trans>
          </p>
          <p>
            <span>PhoneBox</span>
            <br />
            <span>
              <Trans>Customer Support Team</Trans>
            </span>
            <br />
            <span>658 Seymour St. Vancouver, BC V6B 3K4</span>
          </p>
        </Row>
        <Row
          style={{
            marginBottom: "40px",
          }}
        >
          <h6>
            <Trans>
              Commission for Complaints for Telecom-television Services (CCTS)
            </Trans>
          </h6>
          <div className={Styles.cctsContentContainer}>
            <img src="/images/support/concern/ccts-logo.png" alt="CCTS Logo" />
            <div>
              <p>
                <Trans>
                  CCTS is an independent agency whose mandate is to resolve
                  complaints of consumers about their telecom and TV services,
                  and complaints of small business customers about their telecom
                  services, free of charge. If you have a complaint about your
                  telephone, wireless, internet or TV service, you must first
                  try to resolve it directly with your service provider. If you
                  have done so and have been unable to reach a satisfactory
                  resolution, CCTS may be able to help you.
                </Trans>
              </p>
              <p>
                <Trans>
                  To learn more about CCTS, you may visit its website at
                </Trans>{" "}
                <a href="http://http://www.ccts-cprst.ca/" target="_blank">
                  www.ccts-cprst.ca
                </a>{" "}
                <Trans>or call toll-free at</Trans>
                <a href="tel:18882211687">1-888-221-1687</a>.
              </p>
            </div>
          </div>
        </Row>
      </Container>
      <Container className={Styles.resolveYourConcern} id="report-a-concern">
        <h2 style={{ marginBottom: "20px" }}>
          <Trans>We're Ready to Hear Your Concern</Trans>
        </h2>
        <p>
          <Trans>
            We are dedicated to addressing any issues or concerns you may have
            with our services. If you're dealing with an unresolved matter,
            you've reached the right place for us to help find a solution.
          </Trans>
        </p>
        <Form
          onSubmit={async (e) => {
            const formElement = e.currentTarget;
            const submitButton = document.getElementById(
              "form-submit-button:report-concern"
            ) as HTMLButtonElement;
            if (submitButton) {
              submitButton.disabled = true;
            }
            e.preventDefault();
            if (isMissingSelect.findIndex((s) => s) !== -1) {
              alert("Please select all required information");
              return;
            }

            const formData = new FormData(formElement);
            const data: Record<string, string | boolean> = {};
            formData.forEach((value, key) => {
              data[key] = value as string;
            });
            data["HasContactedBefore"] = data["HasContactedBefore"] === "1";

            try {
              const response = await api.reportCustomerConcern(data);
              if (response && response.data) {
                if (response.data.Message) {
                  alert(response.data.Message);
                }
                if (response.data.IsSuccess) {
                  formElement.reset();
                  goTo("/support");
                }
              }
            } catch (error) {
              console.log(error);
            } finally {
              if (submitButton) {
                submitButton.disabled = false;
              }
            }
          }}
        >
          <Form.Group className="mb-3">
            <Form.Label htmlFor="contacted-before">
              <Trans>Have you contacted us about this issue before?</Trans>
            </Form.Label>
            <Form.Select
              id="contacted-before"
              name="HasContactedBefore"
              required
              onChange={() => updateMissingSelect(0)}
            >
              <option disabled selected>
                -
              </option>
              <option value="1">
                <Trans>Yes</Trans>
              </option>
              <option value="0">
                <Trans>No</Trans>
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="issue-about">
              <Trans>What service is your issue about?</Trans>
            </Form.Label>
            <Form.Select
              id="issue-about"
              name="Service"
              required
              onChange={() => updateMissingSelect(1)}
            >
              <option disabled selected>
                -
              </option>
              <option value="Mobile plans">
                <Trans>Mobile plans</Trans>
              </option>
              <option value="Home Internet">
                <Trans>Home Internet</Trans>
              </option>
              <option value="General">
                <Trans>General</Trans>
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="issue-related-to">
              <Trans>What is the issue related to?</Trans>
            </Form.Label>
            <Form.Select
              id="issue-related-to"
              name="IssueCategory"
              required
              onChange={() => updateMissingSelect(2)}
            >
              <option disabled selected>
                -
              </option>
              <option value="With my bill">
                <Trans>With my bill</Trans>
              </option>
              <option value="With my experience">
                <Trans>With my experience</Trans>
              </option>
              <option value="A technical concern">
                <Trans>A technical concern</Trans>
              </option>
              <option value="Others">
                <Trans>Others</Trans>
              </option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="issue-detail-text-area">
              <Trans>Tell us more about your issue</Trans>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              maxLength={300}
              id="issue-detail-text-area"
              name="IssueDescription"
              required
            />
            <Form.Text>
              (<Trans>300 characters max</Trans>)
            </Form.Text>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="resolve-text-area">
              <Trans>How can we resolve this for you?</Trans>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              maxLength={300}
              id="resolve-text-area"
              name="DesiredResolution"
              required
            />
            <Form.Text>
              (<Trans>300 characters max</Trans>)
            </Form.Text>
          </Form.Group>
          <h4>
            <Trans>Your contact information</Trans>
          </h4>
          <p>
            <Trans>
              Enter your contact information if you'd like us to follow up with
              you about this issue.
            </Trans>
          </p>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="first-name">
              <Trans>First Name</Trans>
            </Form.Label>
            <Form.Control
              type="text"
              id="first-name"
              name="FirstName"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="last-name">
              <Trans>Last Name</Trans>
            </Form.Label>
            <Form.Control type="text" id="last-name" name="LastName" required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="phone-or-customer-id-select">
              <Trans>
                To help us look up your file, please provide your customer ID or
                a phone number associated with your account.
              </Trans>
            </Form.Label>
            <Form.Select
              id="phone-or-customer-id-select"
              name="AccountInfoType"
              onChange={(e) => {
                updatePhoneOrCustomerId(e.currentTarget.value);
                updateMissingSelect(3);
              }}
              required
            >
              <option disabled selected>
                -
              </option>
              <option value="Phone Number">
                <Trans>Phone Number</Trans>
              </option>
              <option value="Customer ID">
                <Trans>Customer ID</Trans>
              </option>
            </Form.Select>
          </Form.Group>
          {phoneOrCustomerId !== "-" && (
            <Form.Group className="mb-3">
              <Form.Label htmlFor="phone-number-or-customer-id">
                {phoneOrCustomerId}
              </Form.Label>
              <Form.Control
                type="tel"
                pattern="^[0-9]*$"
                id="phone-number-or-customer-id"
                name="AccountInfoValue"
                minLength={10}
                required
              />
              <Form.Text>
                <Trans>
                  This phone number should be the phone number associated with
                  your PhoneBox account
                </Trans>
              </Form.Text>
            </Form.Group>
          )}
          <Form.Group className="mb-3">
            <Form.Label htmlFor="contact-through">
              <Trans>Please let us know how we should contact you</Trans>
            </Form.Label>
            <Form.Select
              id="contact-through"
              name="ContactMethod"
              onChange={(e) => {
                updateContactThrough(e.currentTarget.value);
                updateMissingSelect(4);
              }}
              required
            >
              <option disabled selected>
                -
              </option>
              <option value="Email">
                <Trans>Email</Trans>
              </option>
              <option value="Phone Number">
                <Trans>Phone Number</Trans>
              </option>
              <option value="Don't contact me">
                <Trans>Don't contact me</Trans>
              </option>
            </Form.Select>
          </Form.Group>
          {contactThrough === "Don't contact me" ||
          contactThrough === "-" ? null : (
            <Form.Group className="mb-3">
              <Form.Label htmlFor="contact-through-input">
                {contactThrough}
              </Form.Label>
              <Form.Control
                type={contactThrough === "Email" ? "email" : "tel"}
                pattern={contactThrough === "Email" ? null : "^[0-9]*$"}
                maxLength={contactThrough === "Email" ? null : 11}
                minLength={contactThrough === "Email" ? null : 10}
                id="contact-through-input"
                name="ContactDetails"
                required
              />
            </Form.Group>
          )}
          <Button
            variant="primary"
            type="submit"
            id="form-submit-button:report-concern"
          >
            <Trans>Submit</Trans>
          </Button>
        </Form>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
